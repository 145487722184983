import { Injectable, inject } from '@angular/core';
import { catchError, exhaustMap, filter, first, map, mergeMap, of } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { CourseStore } from './course.reducer';
import { BaseEffect } from '../base.effect';
import { CoursesService } from '../../../../../admin/src/app/dashboard/intermediary-desk/cifm/courses/courses.service';

@Injectable()
export class CourseEffect extends BaseEffect {
  protected service = inject(CoursesService);

  fetchAll = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseStore.actions.API.fetchAll),
      exhaustMap((action) =>
        this.service.searchCourses2().pipe(
          map((r) => CourseStore.actions.API.fetchAllSuccess({ result: r })),
          catchError((error) => of(CourseStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchAllIfEmpty = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseStore.actions.API.fetchAllIfEmpty),
      mergeMap((action) =>
        this.store.select(CourseStore.selectors.selectAll).pipe(
          first(),
          map((r) => ({ existing: r, action })),
        ),
      ),
      filter((action) => !action.existing.length),
      exhaustMap((action) =>
        this.service.searchCourses2().pipe(
          map((r) => CourseStore.actions.API.fetchAllSuccess({ result: r })),
          catchError((error) => of(CourseStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchSingle = createEffect(() =>
    this.actions$.pipe(
      ofType(CourseStore.actions.API.fetchSingle),
      exhaustMap((action) =>
        this.service.searchCourses2(action.query).pipe(
          map((r) => CourseStore.actions.API.fetchSingleSuccess({ result: r?.[0] })),
          catchError((error) => of(CourseStore.actions.API.fetchSingleError({ error }))),
        ),
      ),
    ),
  );
}
